import { Button, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import React from 'react'
import MainLayout from '../components/MainLayout'
import "./result.scss"

export default function Result({ children, pageContext, location }) {
  const wasResult = location?.state?.matchPercentage != null
  return (
    <MainLayout>
      <div className="match-title">
        {wasResult && (
          <Typography variant="h6">
            You matched with...
          </Typography>
        )}
        <Typography variant="h3">
          {pageContext.frontmatter.title}
        </Typography>
        {wasResult && (
          <Typography variant="overline">
            {Math.round(location.state.matchPercentage * 100)}% match
          </Typography>
        )}
      </div>
      {children}
      <div className="retake-section">
        <Button size="large" variant="contained" color="secondary" component={Link} to="/quiz">
          Take the Quiz{wasResult && " Again"}
        </Button>
      </div>
    </MainLayout>
  )
}